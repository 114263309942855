async function fetchRedirectUrl (s) {
  const response = await fetch('url.php?s=' + s)
  if (response.ok) {
    return (await response.json()).url
  } else {
    throw new Error('could not fetch redirect url')
  }
}

const main = async (searchParams) => {
  const $invalidRequest = document.getElementById('invalid-request')
  const $expiredRequest = document.getElementById('expired-request')

  if (!searchParams.has('s')) {
    $invalidRequest.classList.remove('hidden')
    return
  }

  try {
    const redirectUrl = await fetchRedirectUrl(searchParams.get('s'))
    console.debug('redirect url: ' + redirectUrl)
    window.location.replace(redirectUrl)
  } catch (error) {
    console.debug(error)
    $expiredRequest.classList.remove('hidden')
  }
}

const searchParams = new URLSearchParams(decodeURI(window.location.search))
main(searchParams)
